/** Global component enums */

export enum EPosition {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum ERadioType {
  NONE = 'none',
  YES = 'yes',
  NO = 'no',
}

/** Component specific enums */

/** basic-button.vue */
export enum EButtonStyle {
  DEFAULT = 'default',
  LIGHT = 'light',
  INVERTED = 'inverted',
  CTA = 'cta',
}

export enum EButtonType {
  BUTTON = 'button',
  LINK = 'link',
  ROUTERLINK = 'routerlink',
}

/** bullet-list.vue */
export enum EListType {
  Bullet = 0,
  Number = 1,
  Checkmark = 2,
}
