import type { OfficeData } from '~~/src/server/transformers/EntityTransformers/officeTransformer';
import { ERadioType } from '../../enums/componentProperties';
import type {
  Locations_Location_Entry,
  OfficeMarket_Office_Entry,
} from '../../gql/schema';
import dayjs, { formatDate } from '../../helpers/dayJs';
import { extractOfficeRoomRent, getFormattedPrice } from './utils';

export enum EOfficeRowType {
  COLD_ROOM_RENT = 'coldRoomRent',
  ROOM_RENT = 'roomRent',
  PLACEHOLDER = 'placeholder',
}

export interface IOfficeTableRow {
  icon?: string;
  specialType?: EOfficeRowType;
  label: string;
  value: string | number | string[] | number[];
}

export default function getOfficeTableRowItems(
  entry: OfficeMarket_Office_Entry,
  isList = false,
): IOfficeTableRow[] {
  const items = [];
  if (!isList && entry.officeBoxNr) {
    items.push({
      icon: 'fal fa-hashtag',
      label: 'office.boxNr',
      value: entry.officeBoxNr,
    });
  }

  const location =
    entry.entryLocation && entry.entryLocation.length != 0
      ? (entry.entryLocation[0] as Locations_Location_Entry)
      : null;

  if (location && location.title) {
    items.push({
      icon: 'fal fa-map-location-dot',
      label: isList ? '' : 'office.location',
      value: location.title,
    });
  }
  if (!isList && entry.officeBranchType) {
    items.push({
      icon: 'fal fa-hospital-user',
      label: 'office.branchType',
      value: useTrans().t(`office.${entry.officeBranchType}`),
    });
  }
  if (entry.officeAreaFrom && entry.officeAreaTo) {
    items.push({
      icon: 'far fa-border-bottom-right',
      label: 'office.objectSize',
      value:
        entry.officeAreaFrom === entry.officeAreaTo
          ? entry.officeAreaFrom + ' m²'
          : entry.officeAreaFrom + ' m²' + ' - ' + entry.officeAreaTo + ' m²',
    });
  }
  if (entry.officeTreatmentRoomsFrom && entry.officeTreatmentRoomsTill) {
    items.push({
      icon: 'far fa-seat-airline',
      label: 'office.treatmentRooms',
      value:
        entry.officeTreatmentRoomsFrom === entry.officeTreatmentRoomsTill
          ? entry.officeTreatmentRoomsFrom
          : entry.officeTreatmentRoomsFrom +
            ' - ' +
            entry.officeTreatmentRoomsTill,
    });
  }
  if (entry.officeRoomsFrom && entry.officeRoomsTo) {
    items.push({
      icon: 'fal fa-compress',
      label: 'office.rooms',
      value:
        entry.officeRoomsFrom === entry.officeRoomsTo
          ? entry.officeRoomsFrom
          : entry.officeRoomsFrom + ' - ' + entry.officeRoomsTo,
    });
  }
  if (entry.officeAmountParkingLots) {
    items.push({
      icon: 'fal fa-square-parking',
      label: 'office.amountParkingLots',
      value: entry.officeAmountParkingLots,
    });
  }
  if (entry.officeStorey) {
    items.push({
      icon: 'far fa-building',
      label: 'office.storey',
      value: entry.officeStorey,
    });
  }
  if (entry.officeSpecialisation) {
    items.push({
      icon: 'fal fa-crown',
      label: 'office.specialisation',
      value: entry.officeSpecialisation,
    });
  }
  if (entry.officeLaboratory) {
    items.push({
      icon: 'fal fa-microscope',
      label: 'office.laboratory',
      value: entry.officeLaboratory,
    });
  }
  if (entry.officeIsExtendable !== ERadioType.NONE) {
    items.push({
      icon: 'fal fa-plus-circle',
      label: 'office.extendable',
      value: useTrans().t(
        entry.officeIsExtendable === ERadioType.YES
          ? 'office.true'
          : 'office.false',
      ),
    });
  }
  if (entry.officeIsAccessible !== ERadioType.NONE) {
    items.push({
      icon: 'fal fa-wheelchair',
      label: 'office.accessible',
      value: useTrans().t(
        entry.officeIsAccessible === ERadioType.YES
          ? 'office.true'
          : 'office.false',
      ),
    });
  }

  const availableFrom =
    entry.officeAvailableFrom === null
      ? null
      : dayjs(entry.officeAvailableFrom ?? dayjs()).toDate();
  if (availableFrom) {
    items.push({
      icon: 'fal fa-door-open',
      label: 'office.availableFrom',
      value: formatDate(availableFrom),
    });
  }
  if (entry.officeConstructionYear) {
    items.push({
      icon: 'fal fa-shovel',
      label: 'office.constructionYear',
      value: entry.officeConstructionYear,
    });
  }

  const {
    coldRoomRent,
    warmRoomRent,
    totalRoomRent,
    coldRoomRentFrom,
    coldRoomRentTo,
  } = extractOfficeRoomRent(entry);

  if (!isList && (coldRoomRent || warmRoomRent || totalRoomRent)) {
    items.push({
      icon: 'fal fa-euro-sign',
      label: 'office.roomRent',
      value: '',
      specialType: EOfficeRowType.ROOM_RENT,
    });
  }
  if (!isList && coldRoomRentFrom && coldRoomRentTo) {
    items.push({
      icon: 'fal fa-euro-sign',
      label: 'office.roomRent',
      value:
        coldRoomRentFrom === coldRoomRentTo
          ? `${getFormattedPrice(coldRoomRentFrom)}/m²`
          : `${
              coldRoomRentFrom % 1 === 0
                ? coldRoomRentFrom
                : useTrans().n(coldRoomRentFrom, 'decimal')
            } - ${getFormattedPrice(coldRoomRentTo)}/m²`,
      specialType: EOfficeRowType.COLD_ROOM_RENT,
    });
  }
  if (entry.officeRealEstatePrice) {
    items.push({
      icon: 'fal fa-euro-sign',
      label: 'office.realEstatePrice',
      value: getFormattedPrice(entry.officeRealEstatePrice),
    });
  }

  if (isList) {
    return items.slice(0, 4);
  }

  return items;
}

// TODO: WIP part of the component types, will be combined with above when the office table is refactored
export function getOfficeTableRowItemsNEW(
  office: OfficeData,
  isList = false,
): IOfficeTableRow[] {
  const items = [];
  if (!isList && office.boxNr) {
    items.push({
      icon: 'fal fa-hashtag',
      label: 'office.boxNr',
      value: office.boxNr,
    });
  }

  if (office.location && office.location.title) {
    items.push({
      icon: 'fal fa-map-location-dot',
      label: isList ? '' : 'office.location',
      value: office.location.title,
    });
  }
  if (!isList && office.branchType) {
    items.push({
      icon: 'fal fa-hospital-user',
      label: 'office.branchType',
      value: useTrans().t(`office.${office.branchType}`),
    });
  }
  if (office.areaFrom && office.areaTo) {
    items.push({
      icon: 'far fa-border-bottom-right',
      label: 'office.objectSize',
      value:
        office.areaFrom === office.areaTo
          ? office.areaFrom + ' m²'
          : office.areaFrom + ' m²' + ' - ' + office.areaTo + ' m²',
    });
  }
  if (office.treatmentRoomsFrom && office.treatmentRoomsTill) {
    items.push({
      icon: 'far fa-seat-airline',
      label: 'office.treatmentRooms',
      value:
        office.treatmentRoomsFrom === office.treatmentRoomsTill
          ? office.treatmentRoomsFrom
          : office.treatmentRoomsFrom + ' - ' + office.treatmentRoomsTill,
    });
  }
  if (office.roomsFrom && office.roomsTo) {
    items.push({
      icon: 'fal fa-compress',
      label: 'office.rooms',
      value:
        office.roomsFrom === office.roomsTo
          ? office.roomsFrom
          : office.roomsFrom + ' - ' + office.roomsTo,
    });
  }
  if (office.parkingLots) {
    items.push({
      icon: 'fal fa-square-parking',
      label: 'office.amountParkingLots',
      value: office.parkingLots,
    });
  }
  if (office.storey) {
    items.push({
      icon: 'far fa-building',
      label: 'office.storey',
      value: office.storey,
    });
  }
  if (office.specialisation) {
    items.push({
      icon: 'fal fa-crown',
      label: 'office.specialisation',
      value: office.specialisation,
    });
  }
  if (office.laboratory) {
    items.push({
      icon: 'fal fa-microscope',
      label: 'office.laboratory',
      value: office.laboratory,
    });
  }
  if (office.isExtendable !== ERadioType.NONE) {
    items.push({
      icon: 'fal fa-plus-circle',
      label: 'office.extendable',
      value: useTrans().t(
        office.isExtendable === ERadioType.YES ? 'office.true' : 'office.false',
      ),
    });
  }
  if (office.isAccessible !== ERadioType.NONE) {
    items.push({
      icon: 'fal fa-wheelchair',
      label: 'office.accessible',
      value: useTrans().t(
        office.isAccessible === ERadioType.YES ? 'office.true' : 'office.false',
      ),
    });
  }

  const availableFrom =
    office.availableFrom === null
      ? null
      : dayjs(office.availableFrom ?? dayjs()).toDate();
  if (availableFrom) {
    items.push({
      icon: 'fal fa-door-open',
      label: 'office.availableFrom',
      value: formatDate(availableFrom),
    });
  }
  if (office.constructionYear) {
    items.push({
      icon: 'fal fa-shovel',
      label: 'office.constructionYear',
      value: office.constructionYear,
    });
  }

  if (
    !isList &&
    (office.coldRoomRent || office.warmRoomRent || office.totalRoomRent)
  ) {
    items.push({
      icon: 'fal fa-euro-sign',
      label: 'office.roomRent',
      value: '',
      specialType: EOfficeRowType.ROOM_RENT,
    });
  }
  if (!isList && office.coldRoomRentFrom && office.coldRoomRentTo) {
    items.push({
      icon: 'fal fa-euro-sign',
      label: 'office.roomRent',
      value:
        office.coldRoomRentFrom === office.coldRoomRentTo
          ? `${getFormattedPrice(office.coldRoomRentFrom)}/m²`
          : `${
              office.coldRoomRentFrom % 1 === 0
                ? office.coldRoomRentFrom
                : useTrans().n(office.coldRoomRentFrom, 'decimal')
            } - ${getFormattedPrice(office.coldRoomRentTo)}/m²`,
      specialType: EOfficeRowType.COLD_ROOM_RENT,
    });
  }
  if (office.realEstatePrice) {
    items.push({
      icon: 'fal fa-euro-sign',
      label: 'office.realEstatePrice',
      value: getFormattedPrice(office.realEstatePrice),
    });
  }

  if (isList) {
    return items.slice(0, 4);
  }

  return items;
}
