import type {
  OfficeMarket_Office_Entry,
  Services_Service_Entry,
  Events_Event_Entry,
  Locations_Location_Entry,
  Persons_Person_Entry,
} from '~~/src/gql/schema';
import dayjs from '~~/src/helpers/dayJs';

export function extractOfficeRoomRent(entry: OfficeMarket_Office_Entry) {
  let coldRoomRent: number | null;
  let warmRoomRent: number | null;
  let totalRoomRent: number | null;
  if (entry.officeRoomRent && entry.officeRoomRent.length > 0) {
    coldRoomRent = entry.officeRoomRent[0].officeRoomColdRent;
    warmRoomRent = entry.officeRoomRent[0].officeRoomWarmRent;
    totalRoomRent = entry.officeRoomRent[0].officeRoomTotalRent;
  }
  const coldRoomRentFrom: number | null = entry.officeRoomColdRentFrom;
  const coldRoomRentTo: number | null = entry.officeRoomColdRentTo;
  return {
    coldRoomRent,
    warmRoomRent,
    totalRoomRent,
    coldRoomRentFrom,
    coldRoomRentTo,
  };
}

export function getFormattedPrice(price: number): string {
  if (price % 1 !== 0) {
    return useTrans().n(price, 'currency');
  }
  return useTrans().n(price, 'currencyNoCents');
}

export function getSpeakers(entry: Events_Event_Entry): Persons_Person_Entry[] {
  if (!entry.hasOwnProperty('eventSpeakers')) {
    return [];
  }
  return entry.eventSpeakers.map(
    (evtSpeaker) => evtSpeaker as Persons_Person_Entry,
  );
}

export function getEntryDate(entry: Events_Event_Entry) {
  return entry.entryDate === null
    ? null
    : dayjs(entry.entryDate ?? dayjs()).toDate();
}

export type AdditionalDates = {
  date: Date;
  startTime: Date;
  endTime: Date;
}[];

export function getAdditionalDates(entry: Events_Event_Entry): AdditionalDates {
  return entry.entryAdditionalDates
    ? entry.entryAdditionalDates.map((date) => {
        return {
          date: dayjs(date.entryAdditionalDate).toDate(),
          startTime: dayjs(date.entryAdditionalStartTime).toDate(),
          endTime: dayjs(date.entryAdditionalEndTime).toDate(),
        };
      })
    : [];
}

export function getLocation(
  entry:
    | Events_Event_Entry
    | OfficeMarket_Office_Entry
    | Services_Service_Entry,
) {
  return entry.entryLocation.length != 0
    ? (entry.entryLocation[0] as Locations_Location_Entry)
    : null;
}

export function checkDatesForConsecutiveness(entry: Events_Event_Entry) {
  const additionalDates = getAdditionalDates(entry);
  if (!additionalDates || additionalDates.length === 0) return false;
  let consecutiveDates = true;
  const mainDate = entry.entryDate;

  for (let i = 0; i < additionalDates.length; i++) {
    if (i === 0) {
      if (
        dayjs(mainDate).add(1, 'day').toDate().toString() ==
        dayjs(additionalDates[i].date).toDate().toString()
      ) {
        continue;
      } else {
        consecutiveDates = false;
        break;
      }
    } else {
      if (
        dayjs(additionalDates[i - 1].date)
          .add(1, 'day')
          .toDate()
          .toString() == dayjs(additionalDates[i].date).toDate().toString()
      ) {
        continue;
      } else {
        consecutiveDates = false;
        break;
      }
    }
  }
  return consecutiveDates;
}
